/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Form, Formik } from 'formik';
import {
	fetchActiveInterviewMode,
	fetchActiveInterviewType,
	fetchActiveInterviewer,
	fetchActiveRecruitmentStatus,
} from '../../../reducers/mastersReducers/mastersAction';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import 'react-datetime/css/react-datetime.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFormData } from '../../../helpers/helperFunction';
import {
	addInterview,
	fetchInterviewsByCandidateId,
	fetchInterviewsById,
	updateInterview,
} from '../../../reducers/Interviews/interviewAction';
import Loader from '../../common/loader/Loader';
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { BackBtn } from '../../../helpers/BackBtn';
import { updateRecruitmentStatusId } from '../../../reducers/Candidates/candidateAction';
import AddInterviewForm from './AddInterviewForm';

interface Props {
	interviewTypeRes: any;
	getActiveInterviewTypes: any;
	getActiveInterviewModes: any;
	getActiveInterviewer: any;
	interviewModeRes: any;
	interviewerRes: any;
	addInterview: any;
	getInterviewById: any;
	interviewLoading: any;
	interviewRes: any;
	updateInterview: any;
	getInterviewByCandidateId: any;
	interviewListRes: any;
	interviewListLoading: any;
	addInterviewloading: any;
	updateInterviewloading: any;
	getActiveRecruitmentStatus: () => void;
	recruitmentStatusRes: any;
	updateRecruitmentstatusId: any;
	candidateRes: any;
}

const AddInterview = ({
	interviewTypeRes,
	getActiveInterviewTypes,
	getActiveInterviewModes,
	getActiveInterviewer,
	interviewerRes,
	interviewModeRes,
	addInterview,
	getInterviewById,
	interviewLoading,
	interviewRes,
	updateInterview,
	getInterviewByCandidateId,
	interviewListLoading,
	interviewListRes,
	addInterviewloading,
	updateInterviewloading,
	getActiveRecruitmentStatus,
	recruitmentStatusRes,
	updateRecruitmentstatusId,
	candidateRes,
}: Props) => {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		getActiveInterviewTypes();
		getActiveInterviewModes();
		getActiveInterviewer();
		getActiveRecruitmentStatus();
		params.interviewId && getInterviewById(params.interviewId);
		params.candidateId && getInterviewByCandidateId(params.candidateId);
	}, []);
	const initialValues = {
		interview_type_id: '',
		interviewer_id: '',
		interview_mode_id: '',
		date: new Date().toISOString().slice(0, 16),
		remarks: '',
		location_link: '',
		candidate_master_id: '',
		recruitment_status_id: 2,
	};
	const validate = Yup.object().shape({
		interview_type_id: Yup.string().required('Interview Type is required'),
		interviewer_id: Yup.string().required('Interviewer is required'),
		interview_mode_id: Yup.string().required('Interview Mode is required'),
		date: Yup.string().required('Date is required'),
		location_link: Yup.string().required('Location/Link is required'),
	});
	const onSubmit = async (values: any) => {
		const updatedValues = {
			...values,
			candidate_master_id:
				typeof params.candidateId === 'string'
					? parseInt(params.candidateId)
					: params.candidateId,
		};

		const formdata = getFormData(updatedValues);
		updateRecruitmentstatusId(
			values.recruitment_status_id,
			Number(params.candidateId),
			location
		);
		if (params.interviewId) {
			updateInterview(
				updatedValues,
				params.interviewId,
				navigate,
				params.candidateId
			);
		}
		if (!params.interviewId) {
			addInterview(formdata, navigate, params.candidateId);
		}
	};

	const colArr = [
		'id',
		'interview type',
		'date',
		'interview mode',
		'interviewer',
		'Location/ Link',
		'remarks',
	];

	return (
		<>
			<div className="py-4 d-flex">
				<BackBtn />
				<span className="fw-bold fs-3">Add Interview</span>
			</div>
			<div className="card collapse show p-9">
				{(interviewLoading || !candidateRes) && <Loader />}
				<Formik
					enableReinitialize={true}
					validationSchema={validate}
					initialValues={
						params.interviewId
							? {
									...interviewRes,
									date: interviewRes?.date,
									interview_mode_id: interviewRes?.interview_mode_id,
									interview_type_id: interviewRes?.interview_type_id,
									interviewer_id: interviewRes?.interviewer_id.id,
									location_link: interviewRes?.location_link,
									remarks: interviewRes?.remarks,
							  }
							: initialValues
					}
					onSubmit={(values: any) => onSubmit(values)}
					render={({ setFieldValue, errors, touched }) => (
						<Form className="form">
							<AddInterviewForm
								errors={errors}
								touched={touched}
								setFieldValue={setFieldValue}
								interviewTypeRes={interviewTypeRes}
								interviewModeRes={interviewModeRes}
								interviewerRes={interviewerRes}
								addInterviewloading={addInterviewloading}
								updateInterviewloading={updateInterviewloading}
								recruitmentStatusRes={recruitmentStatusRes}
							/>
						</Form>
					)}
				/>
			</div>
			<div className="py-4">
				<span className="fw-bold fs-3">Scheduled Interviews</span>
			</div>
			<KTCard>
				<KTCardBody className="py-4">
					{interviewListLoading && <Loader />}
					{!interviewListLoading &&
						interviewListRes &&
						interviewListRes.length === 0 && (
							<div className="head2 d-flex justify-content-center">
								No Interviews Found
							</div>
						)}
					{!interviewListLoading &&
						interviewListRes &&
						interviewListRes.length > 0 && (
							<table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
								<thead>
									<tr>
										{colArr.map((columns: any, index: number) => {
											return (
												<th
													className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
													key={index}
												>
													{columns}
												</th>
											);
										})}
									</tr>
								</thead>
								<tbody className="text-gray-600">
									{!interviewListLoading &&
										interviewListRes &&
										interviewListRes.map((list: any, index: any) => {
											return (
												<tr key={index}>
													<th scope="row">{list.id}</th>
													<td>{list.interview_type.interview_type}</td>
													<td>{list.date}</td>
													<td>{list.interview_mode.interview_mode}</td>
													<td>{list.interviewer_id.name}</td>
													<td>{list.location_link}</td>
													<td>{list.remarks}</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						)}
				</KTCardBody>
			</KTCard>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		interviewTypeRes: state.getActiveInterviewTypeReducer.data,
		interviewerRes: state.getActiveInterviewerReducer.data,
		interviewModeRes: state.getActiveInterviewModeReducer.data,
		recruitmentStatusRes: state.getActiveRecruitmentStatusReducer.data,

		interviewLoading: state.getInterviewByIdReducer.loading,
		interviewRes: state.getInterviewByIdReducer.data.interview,
		candidateRes: state.getInterviewByCandidateIdReducer.data.candidate,

		interviewListLoading: state.getInterviewByCandidateIdReducer.loading,
		interviewListRes: state.getInterviewByCandidateIdReducer.data.interviews,

		addInterviewloading: state.addInterviewReducer.loading,
		updateInterviewloading: state.updateInterviewReducer.loading,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getActiveInterviewTypes: () => dispatch(fetchActiveInterviewType()),
		getActiveInterviewer: () => dispatch(fetchActiveInterviewer()),
		getActiveInterviewModes: () => dispatch(fetchActiveInterviewMode()),

		getInterviewById: (id: any) => dispatch(fetchInterviewsById(id)),
		getInterviewByCandidateId: (id: any) =>
			dispatch(fetchInterviewsByCandidateId(id)),
		getActiveRecruitmentStatus: () => {
			dispatch(fetchActiveRecruitmentStatus() as any);
		},

		addInterview: (formData: any, callback: any, candidateId: any) =>
			dispatch(addInterview(formData, callback, candidateId)),

		updateRecruitmentstatusId: (
			formData: any,
			candidateId: any,
			location: any
		) => dispatch(updateRecruitmentStatusId(formData, candidateId, location)),

		updateInterview: (
			formData: any,
			id: any,
			callback: any,
			candidateId: any
		) => dispatch(updateInterview(formData, id, callback, candidateId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInterview);
