/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-types */
import {
	showToastMessageFailure,
	showToastMessageSuccess,
} from '../../helpers/helperFunction';
import { LOGIN } from '../../helpers/routesConstant';
import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
} from '../actionTypes';
import {
	loginService,
	logoutService,
	resetPasswordService,
} from './authService';

const request = (type: string) => {
	return { type };
};
const success = (type: string, data: object) => {
	return { type, payload: data };
};
const failure = (type: string, err: any) => {
	return { type, payload: err };
};

export const loginAction = (
	formData: any,
	callBackSucess: Function,
	callBackFailure: Function
) => {
	return async (dispatch: any) => {
		dispatch(request(LOGIN_REQUEST));
		await loginService(formData).then(
			(result) => {
				dispatch(success(LOGIN_SUCCESS, result));
				// localStorage.setItem("login_user", JSON.stringify(result.data.user));
				callBackSucess(result.data);
			},
			(error) => {
				localStorage.removeItem('login_user');
				callBackFailure();
				dispatch(failure(LOGIN_FAILURE, error.response.data.message));
			}
		);
	};
};

export const resetPassword = (newPassword: Object, callback: any) => {
	return (dispatch: any) => {
		dispatch(request(RESET_PASSWORD_REQUEST));
		return resetPasswordService(newPassword).then(
			(result: any) => {
				dispatch(success(RESET_PASSWORD_SUCCESS, result.data));
				callback();
				showToastMessageSuccess('Password changed. Login to continue');
			},
			(error: any) => {
				dispatch(failure(RESET_PASSWORD_FAILURE, error.response.data.message));
			}
		);
	};
};
