export const GET_ALL_DEGREE_REQUEST = 'GET_ALL_DEGREE_REQUEST';
export const GET_ALL_DEGREE_SUCCESS = 'GET_ALL_DEGREE_SUCCESS';
export const GET_ALL_DEGREE_FAILURE = 'GET_ALL_DEGREE_FAILURE';

export const GET_ACTIVE_DEGREE_REQUEST = 'GET_ACTIVE_DEGREE_REQUEST';
export const GET_ACTIVE_DEGREE_SUCCESS = 'GET_ACTIVE_DEGREE_SUCCESS';
export const GET_ACTIVE_DEGREE_FAILURE = 'GET_ACTIVE_DEGREE_FAILURE';

export const POST_DEGREE_REQUEST = 'POST_DEGREE_REQUEST';
export const POST_DEGREE_SUCCESS = 'POST_DEGREE_SUCCESS';
export const POST_DEGREE_FAILURE = 'POST_DEGREE_FAILURE';

export const UPDATE_DEGREE_REQUEST = 'UPDATE_DEGREE_REQUEST';
export const UPDATE_DEGREE_SUCCESS = 'UPDATE_DEGREE_SUCCESS';
export const UPDATE_DEGREE_FAILURE = 'UPDATE_DEGREE_FAILURE';

export const GET_DEGREE_BY_ID_REQUEST = 'GET_DEGREE_BY_ID_REQUEST';
export const GET_DEGREE_BY_ID_SUCCESS = 'GET_DEGREE_BY_ID_SUCCESS';
export const GET_DEGREE_BY_ID_FAILURE = 'GET_DEGREE_BY_ID_FAILURE';

export const GET_ALL_SOURCE_REQUEST = 'GET_ALL_SOURCE_REQUEST';
export const GET_ALL_SOURCE_SUCCESS = 'GET_ALL_SOURCE_SUCCESS';
export const GET_ALL_SOURCE_FAILURE = 'GET_ALL_SOURCE_FAILURE';

export const GET_ACTIVE_SOURCE_REQUEST = 'GET_ACTIVE_SOURCE_REQUEST';
export const GET_ACTIVE_SOURCE_SUCCESS = 'GET_ACTIVE_SOURCE_SUCCESS';
export const GET_ACTIVE_SOURCE_FAILURE = 'GET_ACTIVE_SOURCE_FAILURE';

export const POST_SOURCE_REQUEST = 'POST_SOURCE_REQUEST';
export const POST_SOURCE_SUCCESS = 'POST_SOURCE_SUCCESS';
export const POST_SOURCE_FAILURE = 'POST_SOURCE_FAILURE';

export const UPDATE_SOURCE_REQUEST = 'UPDATE_SOURCE_REQUEST';
export const UPDATE_SOURCE_SUCCESS = 'UPDATE_SOURCE_SUCCESS';
export const UPDATE_SOURCE_FAILURE = 'UPDATE_SOURCE_FAILURE';

export const GET_SOURCE_BY_ID_REQUEST = 'GET_SOURCE_BY_ID_REQUEST';
export const GET_SOURCE_BY_ID_SUCCESS = 'GET_SOURCE_BY_ID_SUCCESS';
export const GET_SOURCE_BY_ID_FAILURE = 'GET_SOURCE_BY_ID_FAILURE';

export const GET_ALL_SKILL_TYPE_REQUEST = 'GET_ALL_SKILL_TYPE_REQUEST';
export const GET_ALL_SKILL_TYPE_SUCCESS = 'GET_ALL_SKILL_TYPE_SUCCESS';
export const GET_ALL_SKILL_TYPE_FAILURE = 'GET_ALL_SKILL_TYPE_FAILURE';

export const POST_SKILL_TYPE_REQUEST = 'POST_SKILL_TYPE_REQUEST';
export const POST_SKILL_TYPE_SUCCESS = 'POST_SKILL_TYPE_SUCCESS';
export const POST_SKILL_TYPE_FAILURE = 'POST_SKILL_TYPE_FAILURE';

export const UPDATE_SKILL_TYPE_REQUEST = 'UPDATE_SKILL_TYPE_REQUEST';
export const UPDATE_SKILL_TYPE_SUCCESS = 'UPDATE_SKILL_TYPE_SUCCESS';
export const UPDATE_SKILL_TYPE_FAILURE = 'UPDATE_SKILL_TYPE_FAILURE';

export const GET_SKILL_TYPE_BY_ID_REQUEST = 'GET_SKILL_TYPE_BY_ID_REQUEST';
export const GET_SKILL_TYPE_BY_ID_SUCCESS = 'GET_SKILL_TYPE_BY_ID_SUCCESS';
export const GET_SKILL_TYPE_BY_ID_FAILURE = 'GET_SKILL_TYPE_BY_ID_FAILURE';

export const GET_ALL_SKILL_REQUEST = 'GET_ALL_SKILL_REQUEST';
export const GET_ALL_SKILL_SUCCESS = 'GET_ALL_SKILL_SUCCESS';
export const GET_ALL_SKILL_FAILURE = 'GET_ALL_SKILL_FAILURE';

export const GET_ACTIVE_SKILL_REQUEST = 'GET_ACTIVE_SKILL_REQUEST';
export const GET_ACTIVE_SKILL_SUCCESS = 'GET_ACTIVE_SKILL_SUCCESS';
export const GET_ACTIVE_SKILL_FAILURE = 'GET_ACTIVE_SKILL_FAILURE';

export const POST_SKILL_REQUEST = 'POST_SKILL_REQUEST';
export const POST_SKILL_SUCCESS = 'POST_SKILL_SUCCESS';
export const POST_SKILL_FAILURE = 'POST_SKILL_FAILURE';

export const UPDATE_SKILL_REQUEST = 'UPDATE_SKILL_REQUEST';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_FAILURE = 'UPDATE_SKILL_FAILURE';

export const GET_SKILL_BY_ID_REQUEST = 'GET_SKILL_BY_ID_REQUEST';
export const GET_SKILL_BY_ID_SUCCESS = 'GET_SKILL_BY_ID_SUCCESS';
export const GET_SKILL_BY_ID_FAILURE = 'GET_SKILL_BY_ID_FAILURE';

export const GET_ALL_RECRUITMENT_STATUS_REQUEST =
	'GET_ALL_RECRUITMENT_STATUS_REQUEST';
export const GET_ALL_RECRUITMENT_STATUS_SUCCESS =
	'GET_ALL_RECRUITMENT_STATUS_SUCCESS';
export const GET_ALL_RECRUITMENT_STATUS_FAILURE =
	'GET_ALL_RECRUITMENT_STATUS_FAILURE';

export const GET_ACTIVE_RECRUITMENT_STATUS_REQUEST =
	'GET_ACTIVE_RECRUITMENT_STATUS_REQUEST';
export const GET_ACTIVE_RECRUITMENT_STATUS_SUCCESS =
	'GET_ACTIVE_RECRUITMENT_STATUS_SUCCESS';
export const GET_ACTIVE_RECRUITMENT_STATUS_FAILURE =
	'GET_ACTIVE_RECRUITMENT_STATUS_FAILURE';

export const POST_RECRUITMENT_STATUS_REQUEST =
	'POST_RECRUITMENT_STATUS_REQUEST';
export const POST_RECRUITMENT_STATUS_SUCCESS =
	'POST_RECRUITMENT_STATUS_SUCCESS';
export const POST_RECRUITMENT_STATUS_FAILURE =
	'POST_RECRUITMENT_STATUS_FAILURE';

export const UPDATE_RECRUITMENT_STATUS_REQUEST =
	'UPDATE_RECRUITMENT_STATUS_REQUEST';
export const UPDATE_RECRUITMENT_STATUS_SUCCESS =
	'UPDATE_RECRUITMENT_STATUS_SUCCESS';
export const UPDATE_RECRUITMENT_STATUS_FAILURE =
	'UPDATE_RECRUITMENT_STATUS_FAILURE';

export const GET_RECRUITMENT_STATUS_BY_ID_REQUEST =
	'GET_RECRUITMENT_STATUS_BY_ID_REQUEST';
export const GET_RECRUITMENT_STATUS_BY_ID_SUCCESS =
	'GET_RECRUITMENT_STATUS_BY_ID_SUCCESS';
export const GET_RECRUITMENT_STATUS_BY_ID_FAILURE =
	'GET_RECRUITMENT_STATUS_BY_ID_FAILURE';

export const GET_ALL_MODE_OF_WORK_REQUEST = 'GET_ALL_MODE_OF_WORK_REQUEST';
export const GET_ALL_MODE_OF_WORK_SUCCESS = 'GET_ALL_MODE_OF_WORK_SUCCESS';
export const GET_ALL_MODE_OF_WORK_FAILURE = 'GET_ALL_MODE_OF_WORK_FAILURE';

export const GET_ACTIVE_MODE_OF_WORK_REQUEST =
	'GET_ACTIVE_MODE_OF_WORK_REQUEST';
export const GET_ACTIVE_MODE_OF_WORK_SUCCESS =
	'GET_ACTIVE_MODE_OF_WORK_SUCCESS';
export const GET_ACTIVE_MODE_OF_WORK_FAILURE =
	'GET_ACTIVE_MODE_OF_WORK_FAILURE';

export const POST_MODE_OF_WORK_REQUEST = 'POST_MODE_OF_WORK_REQUEST';
export const POST_MODE_OF_WORK_SUCCESS = 'POST_MODE_OF_WORK_SUCCESS';
export const POST_MODE_OF_WORK_FAILURE = 'POST_MODE_OF_WORK_FAILURE';

export const UPDATE_MODE_OF_WORK_REQUEST = 'UPDATE_MODE_OF_WORK_REQUEST';
export const UPDATE_MODE_OF_WORK_SUCCESS = 'UPDATE_MODE_OF_WORK_SUCCESS';
export const UPDATE_MODE_OF_WORK_FAILURE = 'UPDATE_MODE_OF_WORK_FAILURE';

export const GET_MODE_OF_WORK_BY_ID_REQUEST = 'GET_MODE_OF_WORK_BY_ID_REQUEST';
export const GET_MODE_OF_WORK_BY_ID_SUCCESS = 'GET_MODE_OF_WORK_BY_ID_SUCCESS';
export const GET_MODE_OF_WORK_BY_ID_FAILURE = 'GET_MODE_OF_WORK_BY_ID_FAILURE';

export const GET_ALL_INTERVIEW_TYPE_REQUEST = 'GET_ALL_INTERVIEW_TYPE_REQUEST';
export const GET_ALL_INTERVIEW_TYPE_SUCCESS = 'GET_ALL_INTERVIEW_TYPE_SUCCESS';
export const GET_ALL_INTERVIEW_TYPE_FAILURE = 'GET_ALL_INTERVIEW_TYPE_FAILURE';

export const GET_ACTIVE_INTERVIEW_TYPE_REQUEST =
	'GET_ACTIVE_INTERVIEW_TYPE_REQUEST';
export const GET_ACTIVE_INTERVIEW_TYPE_SUCCESS =
	'GET_ACTIVE_INTERVIEW_TYPE_SUCCESS';
export const GET_ACTIVE_INTERVIEW_TYPE_FAILURE =
	'GET_ACTIVE_INTERVIEW_TYPE_FAILURE';

export const POST_INTERVIEW_TYPE_REQUEST = 'POST_INTERVIEW_TYPE_REQUEST';
export const POST_INTERVIEW_TYPE_SUCCESS = 'POST_INTERVIEW_TYPE_SUCCESS';
export const POST_INTERVIEW_TYPE_FAILURE = 'POST_INTERVIEW_TYPE_FAILURE';

export const UPDATE_INTERVIEW_TYPE_REQUEST = 'UPDATE_INTERVIEW_TYPE_REQUEST';
export const UPDATE_INTERVIEW_TYPE_SUCCESS = 'UPDATE_INTERVIEW_TYPE_SUCCESS';
export const UPDATE_INTERVIEW_TYPE_FAILURE = 'UPDATE_INTERVIEW_TYPE_FAILURE';

export const GET_INTERVIEW_TYPE_BY_ID_REQUEST =
	'GET_INTERVIEW_TYPE_BY_ID_REQUEST';
export const GET_INTERVIEW_TYPE_BY_ID_SUCCESS =
	'GET_INTERVIEW_TYPE_BY_ID_SUCCESS';
export const GET_INTERVIEW_TYPE_BY_ID_FAILURE =
	'GET_INTERVIEW_TYPE_BY_ID_FAILURE';

export const GET_ALL_INTERVIEWER_REQUEST = 'GET_ALL_INTERVIEWER_REQUEST';
export const GET_ALL_INTERVIEWER_SUCCESS = 'GET_ALL_INTERVIEWER_SUCCESS';
export const GET_ALL_INTERVIEWER_FAILURE = 'GET_ALL_INTERVIEWER_FAILURE';

export const GET_ACTIVE_INTERVIEWER_REQUEST = 'GET_ACTIVE_INTERVIEWER_REQUEST';
export const GET_ACTIVE_INTERVIEWER_SUCCESS = 'GET_ACTIVE_INTERVIEWER_SUCCESS';
export const GET_ACTIVE_INTERVIEWER_FAILURE = 'GET_ACTIVE_INTERVIEWER_FAILURE';

export const POST_INTERVIEWER_REQUEST = 'POST_INTERVIEWER_REQUEST';
export const POST_INTERVIEWER_SUCCESS = 'POST_INTERVIEWER_SUCCESS';
export const POST_INTERVIEWER_FAILURE = 'POST_INTERVIEWER_FAILURE';

export const UPDATE_INTERVIEWER_REQUEST = 'UPDATE_INTERVIEWER_REQUEST';
export const UPDATE_INTERVIEWER_SUCCESS = 'UPDATE_INTERVIEWER_SUCCESS';
export const UPDATE_INTERVIEWER_FAILURE = 'UPDATE_INTERVIEWER_FAILURE';

export const GET_INTERVIEWER_BY_ID_REQUEST = 'GET_INTERVIEWER_BY_ID_REQUEST';
export const GET_INTERVIEWER_BY_ID_SUCCESS = 'GET_INTERVIEWER_BY_ID_SUCCESS';
export const GET_INTERVIEWER_BY_ID_FAILURE = 'GET_INTERVIEWER_BY_ID_FAILURE';

export const GET_ALL_INTERVIEW_MODE_REQUEST = 'GET_ALL_INTERVIEW_MODE_REQUEST';
export const GET_ALL_INTERVIEW_MODE_SUCCESS = 'GET_ALL_INTERVIEW_MODE_SUCCESS';
export const GET_ALL_INTERVIEW_MODE_FAILURE = 'GET_ALL_INTERVIEW_MODE_FAILURE';

export const GET_ACTIVE_INTERVIEW_MODE_REQUEST =
	'GET_ACTIVE_INTERVIEW_MODE_REQUEST';
export const GET_ACTIVE_INTERVIEW_MODE_SUCCESS =
	'GET_ACTIVE_INTERVIEW_MODE_SUCCESS';
export const GET_ACTIVE_INTERVIEW_MODE_FAILURE =
	'GET_ACTIVE_INTERVIEW_MODE_FAILURE';

export const POST_INTERVIEW_MODE_REQUEST = 'POST_INTERVIEW_MODE_REQUEST';
export const POST_INTERVIEW_MODE_SUCCESS = 'POST_INTERVIEW_MODE_SUCCESS';
export const POST_INTERVIEW_MODE_FAILURE = 'POST_INTERVIEW_MODE_FAILURE';

export const UPDATE_INTERVIEW_MODE_REQUEST = 'UPDATE_INTERVIEW_MODE_REQUEST';
export const UPDATE_INTERVIEW_MODE_SUCCESS = 'UPDATE_INTERVIEW_MODE_SUCCESS';
export const UPDATE_INTERVIEW_MODE_FAILURE = 'UPDATE_INTERVIEW_MODE_FAILURE';

export const GET_INTERVIEW_MODE_BY_ID_REQUEST =
	'GET_INTERVIEW_MODE_BY_ID_REQUEST';
export const GET_INTERVIEW_MODE_BY_ID_SUCCESS =
	'GET_INTERVIEW_MODE_BY_ID_SUCCESS';
export const GET_INTERVIEW_MODE_BY_ID_FAILURE =
	'GET_INTERVIEW_MODE_BY_ID_FAILURE';

export const GET_ACTIVE_CANDIDATE_REQUEST = 'GET_ACTIVE_CANDIDATE_REQUEST';
export const GET_ACTIVE_CANDIDATE_SUCCESS = 'GET_ACTIVE_CANDIDATE_SUCCESS';
export const GET_ACTIVE_CANDIDATE_FAILURE = 'GET_ACTIVE_CANDIDATE_FAILURE';

export const GET_ALL_CANDIDATE_REQUEST = 'GET_ALL_CANDIDATE_REQUEST';
export const GET_ALL_CANDIDATE_SUCCESS = 'GET_ALL_CANDIDATE_SUCCESS';
export const GET_ALL_CANDIDATE_FAILURE = 'GET_ALL_CANDIDATE_FAILURE';

export const GET_CANDIDATE_BY_ID_REQUEST = 'GET_CANDIDATE_BY_ID_REQUEST';
export const GET_CANDIDATE_BY_ID_SUCCESS = 'GET_CANDIDATE_BY_ID_SUCCESS';
export const GET_CANDIDATE_BY_ID_FAILURE = 'GET_CANDIDATE_BY_ID_FAILURE';

export const GET_CANDIDATE_SKILLS_REQUEST = 'GET_CANDIDATE_SKILLS_REQUEST';
export const GET_CANDIDATE_SKILLS_SUCCESS = 'GET_CANDIDATE_SKILLS_SUCCESS';
export const GET_CANDIDATE_SKILLS_FAILURE = 'GET_CANDIDATE_SKILLS_FAILURE';

export const ADD_CANDIDATE_REQUEST = 'ADD_CANDIDATE_REQUEST';
export const ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS';
export const ADD_CANDIDATE_FAILURE = 'ADD_CANDIDATE_FAILURE';

export const IMPORT_CANDIDATE_REQUEST = 'IMPORT_CANDIDATE_REQUEST';
export const IMPORT_CANDIDATE_SUCCESS = 'IMPORT_CANDIDATE_SUCCESS';
export const IMPORT_CANDIDATE_FAILURE = 'IMPORT_CANDIDATE_FAILURE';

export const UPDATE_CANDIDATE_REQUEST = 'UPDATE_CANDIDATE_REQUEST';
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_FAILURE = 'UPDATE_CANDIDATE_FAILURE';

export const ADD_INTERVIEW_REQUEST = 'ADD_INTERVIEW_REQUEST';
export const ADD_INTERVIEW_SUCCESS = 'ADD_INTERVIEW_SUCCESS';
export const ADD_INTERVIEW_FAILURE = 'ADD_INTERVIEW_FAILURE';

export const UPDATE_INTERVIEW_REQUEST = 'UPDATE_INTERVIEW_REQUEST';
export const UPDATE_INTERVIEW_SUCCESS = 'UPDATE_INTERVIEW_SUCCESS';
export const UPDATE_INTERVIEW_FAILURE = 'UPDATE_INTERVIEW_FAILURE';

export const GET_INTERVIEW_BY_ID_REQUEST = 'GET_INTERVIEW_BY_ID_REQUEST';
export const GET_INTERVIEW_BY_ID_SUCCESS = 'GET_INTERVIEW_BY_ID_SUCCESS';
export const GET_INTERVIEW_BY_ID_FAILURE = 'GET_INTERVIEW_BY_ID_FAILURE';

export const GET_INTERVIEW_REQUEST = 'GET_INTERVIEW_REQUEST';
export const GET_INTERVIEW_SUCCESS = 'GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_FAILURE = 'GET_INTERVIEW_FAILURE';

export const GET_ACTIVE_CANDIDATE_INTERVIEW_REQUEST =
	'GET_ACTIVE_CANDIDATE_INTERVIEW_REQUEST';
export const GET_ACTIVE_CANDIDATE_INTERVIEW_SUCCESS =
	'GET_ACTIVE_CANDIDATE_INTERVIEW_SUCCESS';
export const GET_ACTIVE_CANDIDATE_INTERVIEW_FAILURE =
	'GET_ACTIVE_CANDIDATE_INTERVIEW_FAILURE';

export const GET_INTERVIEW_BY_CANDIDATE_ID_REQUEST =
	'GET_INTERVIEW_BY_CANDIDATE_ID_REQUEST';
export const GET_INTERVIEW_BY_CANDIDATE_ID_SUCCESS =
	'GET_INTERVIEW_BY_CANDIDATE_ID_SUCCESS';
export const GET_INTERVIEW_BY_CANDIDATE_ID_FAILURE =
	'GET_INTERVIEW_BY_CANDIDATE_ID_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_RECRUITMENT_STATUS_ID_REQUEST =
	'UPDATE_RECRUITMENT_STATUS_ID_REQUEST';
export const UPDATE_RECRUITMENT_STATUS_ID_SUCESS =
	'UPDATE_RECRUITMENT_STATUS_ID_SUCESS';
export const UPDATE_RECRUITMENT_STATUS_ID_FAILURE =
	'UPDATE_RECRUITMENT_STATUS_ID_FAILURE';
