/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu';
import { useAuth } from '../../../../app/components/auth';
import { Form, Formik } from 'formik';
import { getFormData } from '../../../../app/helpers/helperFunction';
import { importCandidateCsv } from '../../../../app/reducers/Candidates/candidateAction';
import { useNavigate } from 'react-router-dom';
import { PLEASE_WAIT } from '../../../../app/helpers/globalConstant';

const itemClass = 'ms-1 ms-lg-3';
const userAvatarClass = 'symbol-35px symbol-md-40px';

interface UserDetails {
	name: string;
}
interface Props {
	importCandidateCSV: any;
	csvLoading: any;
}

const Navbar = ({ importCandidateCSV, csvLoading }: Props) => {
	const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
	const { currentUser } = useAuth();
	const navigate = useNavigate();

	const fileRef = useRef<HTMLInputElement>(null);
	const initialValues = {
		file: '',
	};
	const onSubmit = (formdata: any) => {
		importCandidateCSV(formdata, navigate);
	};

	useEffect(() => {
		if (currentUser) {
			const { name } = currentUser as unknown as UserDetails;
			setUserDetails({ name });
		}
	}, [currentUser]);

	return (
		<div className="app-navbar flex-shrink-0">
			<div className={clsx('app-navbar-item', itemClass)}>
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					onSubmit={(values: any) => onSubmit(values)}
					render={({ setFieldValue }) => (
						<Form className="form">
							{csvLoading ? (
								<button
									type="submit"
									className="btn btn-light mt-3"
									disabled={csvLoading}
								>
									<span
										className="indicator-progress"
										style={{ display: 'block' }}
									>
										{PLEASE_WAIT}
									</span>
								</button>
							) : (
								<>
									<button
										type="button"
										className="btn btn-light mx-2"
										onClick={() => {
											fileRef?.current?.click();
										}}
									>
										<i className="fa fa-upload"></i> Import Candidates
									</button>
									<input
										ref={fileRef}
										type="file"
										name="file"
										autoComplete="off"
										className={clsx('form-control bg-transparent d-none')}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											const selectedFile = event.target.files?.[0];
											if (selectedFile) {
												setFieldValue('file', selectedFile.name);
												const formdata = getFormData({
													...initialValues,
													file: selectedFile,
												});
												onSubmit(formdata);
											}
										}}
									/>
								</>
							)}
						</Form>
					)}
				/>

				<div
					className={clsx('cursor-pointer symbol', userAvatarClass)}
					data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
				>
					<div className="symbol symbol-50px">
						<span className="symbol-label bg-light-primary text-primary fw-bold fs-4">
							{userDetails?.name?.charAt(0) || 'A'}
						</span>
					</div>
				</div>
				<HeaderUserMenu />
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		csvLoading: state.importCSVReducer.loading,
		csvRes: state.importCSVReducer.data,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		importCandidateCSV: (details: any, navigate: any) => {
			dispatch(importCandidateCsv(details, navigate) as any);
		},
	};
};
const connectComponent = connect(mapStateToProps, mapDispatchToProps)(Navbar);
export default connectComponent;
