export const DEGREE = '/degreemaster';
export const ACTIVE_DEGREE = '/getactivedegree';
export const SOURCE = '/sourcemaster';
export const ACTIVE_SOURCE = '/getactivesource';
export const SKILL_TYPE = '/skilltypemaster';
export const SKILL = '/skillmaster';
export const ACTIVE_SKILL = '/getactiveskill';
export const RECRUITMENT_STATUS = '/recruitmentstatusmaster';
export const ACTIVE_RECRUITMENT_STATUS = '/getactiverecruitmentstatus';
export const MODE_OF_WORK = '/modeofworkmaster';
export const ACTIVE_MODE_OF_WORK = '/getactiveremodeofwork';
export const INTERVIEW_TYPE = '/interviewtypemaster';
export const ACTIVE_INTERVIEW_TYPE = '/getactiveinterviewtype';
export const INTERVIEWER = '/interviewermaster';
export const ACTIVE_INTERVIEWER = '/getactiveinterviewer';
export const INTERVIEW_MODE = '/interviewmodemaster';
export const ACTIVE_INTERVIEW_MODE = '/getactiveinterviewmode';
export const CANDIDATE = '/getactivecandidates';
export const CANDIDATE_REPORT = '/candidatemaster';
export const CANDIDATE_SKILLS = '/getCandidateSkills';
export const INTERVIEW = '/interviews';
export const ACTIVE_CANDIDATE_INTERVIEW = '/getActiveCandidateInterview';
export const INTERVIEW_BY_ID = '/interview';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const RESET_PASSWORD = '/changepassword';
export const UPDATE_RECRUITMENT_STATUS = '/candidates';
