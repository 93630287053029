/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import {
	fetchCandidateById,
	updateRecruitmentStatusId,
} from '../../../reducers/Candidates/candidateAction';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../common/loader/Loader';
import { fetchInterviewsByCandidateId } from '../../../reducers/Interviews/interviewAction';
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers';
import { ADD_CANDIDATE, ADD_INTERVIEW } from '../../../helpers/routesConstant';
import { BackBtn } from '../../../helpers/BackBtn';
import CandidateDetailsAccordian from './CandidateDetailsAccordian';
import { Field, Form, Formik } from 'formik';
import { NO_RECORDS_FOUND } from '../../../helpers/globalConstant';
import clsx from 'clsx';
import { fetchActiveRecruitmentStatus } from '../../../reducers/mastersReducers/mastersAction';
import CandidateSkills from './CandidateSkills';

interface Props {
	candidateRes: any;
	getCandidateById: any;
	getActiveRecruitmentStatus: any;
	getInterviewById: any;
	interviewLoading: any;
	interviewRes: any;
	skillRes: any;
	prevCompanyRes: any;
	recruitmentStatusRes: any;
	updateRecruitmentstatusId: any;
}

const CandidateDetail = ({
	candidateRes,
	getCandidateById,
	getInterviewById,
	interviewLoading,
	interviewRes,
	skillRes,
	prevCompanyRes,
	recruitmentStatusRes,
	getActiveRecruitmentStatus,
	updateRecruitmentstatusId,
}: Props) => {
	const params = useParams();
	const navigate = useNavigate();
	const [skill, setSkill] = useState('');
	const [previousComapny, setPreviouscomapny] = useState('');
	const colArr = [
		'id',
		'Interview Type',
		'date',
		'Interview Mode',
		'interviewer',
		'Location/ Link',
		'total ratings',
		'Remarks',
		'actions',
	];

	useEffect(() => {
		getCandidateById(params.candidateId);
		getInterviewById(params.candidateId);
		getActiveRecruitmentStatus();
	}, []);

	useEffect(() => {
		handleSkill(skillRes);
	}, [skillRes]);

	useEffect(() => {
		handlePreviouscomapnies(prevCompanyRes);
	}, [prevCompanyRes]);

	const handleSkill = (skillRes: any) => {
		const formattedSkills = skillRes?.map(({ skill, experience }: any) => {
			const experienceText = experience === 1 ? 'year' : 'years';
			return `${skill} (${experience} ${experienceText})`;
		});
		const result = formattedSkills?.join(', ');
		result && setSkill(result);
	};

	const handlePreviouscomapnies = (prevCompanyRes: any) => {
		const formattedRes = prevCompanyRes?.map(
			({ coumpany_name, from, to }: any) => {
				if (coumpany_name) {
					return coumpany_name;
				}
				if (!coumpany_name) {
					return null;
				} else if (coumpany_name) {
					if (from && to) {
						const fromDate = from?.split('-');
						const formattedFromDate = `${fromDate[2]}/${fromDate[1]}/${fromDate[0]}`;
						const toDate = to?.split('-');
						const formattedToDate = `${toDate[2]}/${toDate[1]}/${toDate[0]}`;
						return `${coumpany_name} (${formattedFromDate} -  ${formattedToDate})`;
					}
				}
			}
		);
		const result = formattedRes?.join(', ');
		result && setPreviouscomapny(result);
	};
	const initialValues = {
		recruitment_status_id: candidateRes?.recruitment_status_id || '',
	};
	const onSubmit = (values: any) => {
		params.candidateId &&
			updateRecruitmentstatusId(values, Number(params.candidateId));
	};

	return (
		<>
			<div className=" d-flex justify-content-between">
				<div className="d-flex align-items-center">
					<BackBtn />
					<span className="fw-bold fs-3">Candidate Details</span>
				</div>
				<div>
					<Formik
						enableReinitialize={true}
						initialValues={initialValues}
						onSubmit={(values: any) => {
							// onSubmit(values);
						}}
					>
						{({ values, setFieldValue }) => (
							<Form className="form">
								<Field
									as="select"
									name="recruitment_status_id"
									className={clsx(
										'form-select border-body bg-body w-200px me-3'
									)}
									onChange={(e: any) => onSubmit(e.target.value)}
								>
									<option value={''} disabled>
										Select Recruitment Status
									</option>
									{recruitmentStatusRes && recruitmentStatusRes.length > 0 ? (
										recruitmentStatusRes?.map((list: any, i: any) => (
											<>
												{!params.id && (
													<option key={i} value={list.id}>
														{list.recruitment_status}
													</option>
												)}
												{params.id && (
													<option key={i} value={list.id}>
														{list.recruitment_status}
													</option>
												)}
											</>
										))
									) : (
										<option>{NO_RECORDS_FOUND}</option>
									)}
								</Field>
							</Form>
						)}
					</Formik>
				</div>
				<div className="d-flex">
					<Link to={`${ADD_CANDIDATE}/${params.candidateId}`}>
						<button className="btn btn-white mb-2 mx-2">Edit Candidate</button>
					</Link>

					{/* {candidateRes &&
						candidateRes.resume_id &&
						candidateRes.resume_id !== 'undefined' && (
							<a href={candidateRes.resume_id} target="_blank" rel="noreferrer">
								<button className="btn btn-white mb-2">Resume</button>
							</a>
						)} */}
					<Link to={`${ADD_INTERVIEW}/${params.candidateId}`}>
						<button className="btn btn-primary mb-2 mx-2">
							Schedule Interview
						</button>
					</Link>
				</div>
			</div>

			{!interviewLoading && skill && candidateRes && (
				<>
					<CandidateDetailsAccordian
						candidateRes={candidateRes}
						skill={skill}
						previousComapny={previousComapny}
					/>
				</>
			)}
			<div className="p-4">
				<span className="fw-bold fs-3">Scheduled Interviews</span>
			</div>
			<KTCard className="mb-7">
				<KTCardBody className="py-4">
					{interviewLoading && <Loader />}
					{!interviewLoading && interviewRes && interviewRes.length === 0 && (
						<div className="d-flex justify-content-center head2">
							No Interviews Found
						</div>
					)}
					{!interviewLoading && interviewRes && interviewRes.length > 0 && (
						<table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
							<thead>
								<tr>
									{colArr.map((columns: any, index: number) => {
										return (
											<th
												className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
												key={index}
											>
												{columns}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody className="text-gray-600">
								{!interviewLoading &&
									interviewRes &&
									interviewRes.map((list: any, index: any) => {
										return (
											<tr key={index}>
												<th scope="row">{list.id}</th>
												<td>{list.interview_type.interview_type}</td>
												<td>{list.date}</td>
												<td>{list.interview_mode.interview_mode}</td>
												<td>{list.interviewer_id.name}</td>
												<td>{list.location_link}</td>
												<td>{list.total_rating || '-'}</td>
												<td>{list.remarks}</td>
												<td>
													{' '}
													<span
														className="btn btn-sm btn-icon btn-light-primary me-4"
														onClick={() =>
															navigate(
																`${ADD_INTERVIEW}/${params.candidateId}/${list.id}`
															)
														}
													>
														<KTSVG
															path="/media/icons/duotune/art/art005.svg"
															className="svg-icon-3"
														/>
													</span>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					)}
				</KTCardBody>
			</KTCard>
			<CandidateSkills />
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		interviewLoading: state.getInterviewByCandidateIdReducer.loading,
		recruitmentStatusRes: state.getActiveRecruitmentStatusReducer.data,

		candidateRes: state.getInterviewByCandidateIdReducer.data.candidate,
		interviewRes: state.getInterviewByCandidateIdReducer.data.interviews,
		skillRes: state.getInterviewByCandidateIdReducer.data.skills,
		prevCompanyRes:
			state.getInterviewByCandidateIdReducer.data.previous_companies,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getCandidateById: (id: any) => dispatch(fetchCandidateById(id)),
		getInterviewById: (id: any) => dispatch(fetchInterviewsByCandidateId(id)),
		getActiveRecruitmentStatus: () => dispatch(fetchActiveRecruitmentStatus()),
		updateRecruitmentstatusId: (formData: any, candidateId: any) =>
			dispatch(updateRecruitmentStatusId(formData, candidateId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetail);
