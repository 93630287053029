/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect } from 'react';
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import Loader from '../../common/loader/Loader';
import { connect } from 'react-redux';
import { fetchCandidateSkills } from '../../../reducers/Candidates/candidateAction';
import { useParams } from 'react-router-dom';

const colArr = [
	'id',
	'skill',
	'experiance',
	'self ratings',
	'theory ratings',
	'pratical ratings',
	'total ratings',
];
interface Props {
	candidateSkillsLoading: any;
	candidateSkillsRes: any;
	getCandidateSkills: any;
}

const CandidateSkills = ({
	candidateSkillsLoading,
	candidateSkillsRes,
	getCandidateSkills,
}: Props) => {
	const params = useParams();

	useEffect(() => {
		if (params.candidateId) {
			getCandidateSkills(params.candidateId);
		}
	}, []);
	return (
		<>
			<div className="p-4">
				<span className="fw-bold fs-3">Candidate Skills</span>
			</div>
			<KTCard>
				<KTCardBody className="py-4">
					{candidateSkillsLoading && <Loader />}
					{!candidateSkillsLoading &&
						candidateSkillsRes &&
						candidateSkillsRes.length === 0 && (
							<div className="d-flex justify-content-center head2">
								No data found
							</div>
						)}
					{!candidateSkillsLoading &&
						candidateSkillsRes &&
						candidateSkillsRes.length > 0 && (
							<table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
								<thead>
									<tr>
										{colArr.map((columns: any, index: number) => {
											return (
												<th
													className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
													key={index}
												>
													{columns}
												</th>
											);
										})}
									</tr>
								</thead>
								<tbody className="text-gray-600">
									{!candidateSkillsLoading &&
										candidateSkillsRes &&
										candidateSkillsRes.map((list: any, index: any) => {
											return (
												<tr key={index}>
													<th scope="row">{index + 1}</th>
													<td>{list.skill_master.skill}</td>
													<td>{list.experience}</td>
													<td>{list.self_rating}</td>
													<td>
														{list.theory_rating && (
															<span>
																{list.theory_rating} (
																{list?.theory_interviewer?.name})
															</span>
														)}
													</td>
													<td>
														{list.practical_rating && (
															<span>
																{list.practical_rating} (
																{list?.practical_interviewer?.name})
															</span>
														)}
													</td>
													<td>{list.total_ratings}</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						)}
				</KTCardBody>
			</KTCard>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		candidateSkillsLoading: state.viewCandidateSkillsReducer.loading,
		candidateSkillsRes: state.viewCandidateSkillsReducer.data,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getCandidateSkills: (id: any) => dispatch(fetchCandidateSkills(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSkills);
