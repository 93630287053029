/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/default-param-last */

import {
	IMPORT_CANDIDATE_FAILURE,
	IMPORT_CANDIDATE_REQUEST,
	IMPORT_CANDIDATE_SUCCESS,
} from '../actionTypes';

const initialState = {
	loading: false,
	data: {},
	error: '',
};

interface actionObject {
	type: string;
	payload: any;
}

const importCSVReducer = (state = initialState, action: actionObject) => {
	switch (action.type) {
		case IMPORT_CANDIDATE_REQUEST:
			return {
				...state,
				loading: true,
				error: '',
			};
		case IMPORT_CANDIDATE_SUCCESS:
			return {
				...state,
				loading: false,
				error: '',
				data: action.payload,
			};
		case IMPORT_CANDIDATE_FAILURE:
			return {
				...state,
				loading: false,
				data: '',
				error: action.payload,
			};
		default:
			return state;
	}
};

export { importCSVReducer };
