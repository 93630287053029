/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { KTCardBody, KTSVG } from '../../../../_metronic/helpers';
import Loader from '../../common/loader/Loader';
import { CANDIDATE_DETAIL } from '../../../helpers/routesConstant';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../helpers/helperFunction';
import CandidateReportTableHeader from './CandidateReportTableHeader';

interface Props {
	candidateListLoading: any;
	candidateListRes: any;
	handleSorting: any;
}

const CandidateReportTable = ({
	candidateListLoading,
	candidateListRes,
	handleSorting,
}: Props) => {
	const navigate = useNavigate();

	return (
		<>
			<KTCardBody className="py-4 report-cards">
				{candidateListLoading && <Loader />}
				{!candidateListLoading &&
					candidateListRes &&
					candidateListRes.length === 0 && (
						<div className="head2 d-flex justify-content-center">
							No records found
						</div>
					)}
				{!candidateListLoading &&
					candidateListRes &&
					candidateListRes.length > 0 && (
						<table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
							<CandidateReportTableHeader handleSorting={handleSorting} />
							<tbody className="text-gray-600">
								{!candidateListLoading &&
									candidateListRes &&
									candidateListRes.map((list: any, index: any) => {
										return (
											<tr key={index}>
												<th scope="row">{list.id}</th>
												<td>{list.name}</td>
												{/* <td>{list.contect_no}</td> */}
												<td>{list.degree}</td>
												<td>{list.recruitment_status}</td>
												<td>{list.mode_of_work}</td>
												<td>
													{list.total_experience == 1
														? `${list.total_experience} Year`
														: list.total_experience
														? `${list.total_experience} Years`
														: '-'}
												</td>
												<td>{list.skills}</td>
												<td>{formatDate(list.resume_date)}</td>
												<td>
													{' '}
													<span
														className="btn btn-sm btn-icon btn-light-primary me-1"
														title="View Details"
														onClick={() =>
															navigate(`${CANDIDATE_DETAIL}/${list.id}`)
														}
													>
														<i className="fa fa-eye"></i>
													</span>
													<span
														className="btn btn-sm btn-icon btn-light-warning me-1"
														title="Resume"
													>
														<a
															href={list.resume_id}
															target="_blank"
															rel="noreferrer"
														>
															<KTSVG
																path="/media/icons/duotune/general/gen005.svg"
																className="svg-icon-3"
															/>
														</a>
													</span>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					)}
			</KTCardBody>
		</>
	);
};

export default CandidateReportTable;
