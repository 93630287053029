/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import clsx from 'clsx';
import { Field } from 'formik';
import React, { FocusEvent, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { convertDate } from '../../../helpers/helperFunction';

interface Props {
	setFieldValue: (field: string, value: any) => void;
	setPreviousFile: (file: any) => void;
	setFile: (file: any) => void;
	file: any;
	values: { [key: string]: any };
	errors: { [key: string]: string };
	touched: { [key: string]: boolean };
}

const Step1 = ({
	setFieldValue,
	setPreviousFile,
	setFile,
	file,
	values,
	errors,
	touched,
}: Props) => {
	const fileRef = useRef<HTMLInputElement>(null);
	const params = useParams();
	const [resumeDate, setResumeDate] = useState<Date>(new Date());

	const setDatePickerDate = (date: string): Date | null => {
		const currentDate = new Date(date);
		if (!isNaN(currentDate.getTime())) {
			return currentDate;
		} else {
			return null;
		}
	};

	return (
		<>
			<div className="row mb-lg-6 mt-2">
				<div className="">
					<label className="col-form-label fw-bold fs-6 required">Resume</label>
					<span className="text-gray-600"> (jpg, doc, pdf only)</span>
					<div className="d-flex">
						<button
							type="button"
							className="btn btn-light"
							onClick={() => {
								fileRef?.current?.click();
							}}
						>
							<i className="fa fa-upload"></i> Upload File
						</button>
						<input
							ref={fileRef}
							type="file"
							name="resume_id"
							autoComplete="off"
							className={clsx('form-control bg-transparent d-none')}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const selectedFile = event.target.files?.[0];
								if (selectedFile) {
									setFieldValue('resume_id', selectedFile.name);
									setFile(selectedFile);
									setPreviousFile(selectedFile.name);
								}
							}}
						/>

						<div className="d-flex justify-content-center align-items-center mx-2">
							<span className="text2 fs-6 text-gray">{file && file.name}</span>
						</div>
						{params.id && !file?.name && values?.resume_id && (
							<div className="d-flex justify-content-center align-items-center mx-2">
								<a href={values.resume_id} target="_blank" rel="noreferrer">
									<span className="text2 fs-6 text-gray">View Resume</span>
								</a>
							</div>
						)}
					</div>
					{errors.resume_id && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.resume_id}`}</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="row mb-lg-6 mt-2">
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Candidate Name
					</label>
					<div className="">
						<Field
							placeholder="Candidate Name"
							type="text"
							name="name"
							onBlur={(e: FocusEvent<HTMLInputElement>) => {
								setFieldValue('name', e.target.value.replace(/[^A-Za-z ]/gi, '').trim().replace(/\w\S*/g,function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}));}}
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
						{errors.name && touched.name && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{`${errors.name}`}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">Email</label>
					<div className="">
						<Field
							placeholder="Email"
							type="text"
							name="email"
							onBlur={(e: FocusEvent<HTMLInputElement>) => {
								setFieldValue('email', e.target.value.toLowerCase().trim());}}
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
						{errors.email && touched.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{`${errors.email}`}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Contact Number
					</label>
					<div className="">
						<Field
							placeholder="Contact Number"
							type="text"
							name="contect_no"
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
						{errors.contect_no && touched.contect_no && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{`${errors.contect_no}`}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="row mb-lg-6 mt-2">
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">Address</label>
					<div className="">
						<Field
							placeholder="Address"
							type="text"
							name="address"
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
					</div>
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">City</label>
					<div className="">
						<Field
							placeholder="City"
							type="text"
							name="city"
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
					</div>
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">State</label>
					<div className="">
						<Field
							placeholder="State"
							type="text"
							name="state"
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
					</div>
				</div>
			</div>
			<div className="row mb-lg-6 mt-2">
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">Remarks</label>
					<div className="">
						<Field
							placeholder="Remarks"
							type="text"
							name="remarks"
							autoComplete="off"
							className={clsx('form-control bg-transparent')}
						/>
					</div>
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">Resume Date</label>
					<div className="">
						<DatePicker
							dateFormat={'dd/MM/yyyy'}
							className="form-control bg-transparent"
							selected={
								values.resume_date && values.resume_date.length > 0
									? setDatePickerDate(values.resume_date)
									: new Date()
							}
							onChange={(date) => {
								setResumeDate(date as Date);
								setFieldValue('resume_date', date ? convertDate(date) : '');
							}}
						/>
						{touched.resume_date && errors?.resume_date && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{`${errors?.resume_date}`}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Step1;
