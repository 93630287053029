/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/require-await */
import React from 'react';
import { BackBtn } from '../../../helpers/BackBtn';
import { useFormik } from 'formik';
import { PLEASE_WAIT, SUBMIT } from '../../../helpers/globalConstant';
import { resetPassword } from '../../../reducers/Auth/authAction';
import { connect } from 'react-redux';
import { getFormData } from '../../../helpers/helperFunction';
import { useAuth } from '../core/Auth';

interface Props {
	resetPassword: any;
	res: any;
	error: any;
	loading: any;
}

const ResetPassword = ({ resetPassword, res, error, loading }: Props) => {
	const initialValues = {
		current_password: '',
		new_password: '',
	};

	const { logout } = useAuth();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async () => {
			const newFormData: any = getFormData(formik.values);
			resetPassword(newFormData, logout);
		},
	});

	return (
		<>
			<div className="pt-10 head1 d-flex">
				<BackBtn />
				<span>Reset password</span>
			</div>

			{error && (
				<div className="my-3">
					<div className="alert alert-danger">
						<div className="alert-text">{error}</div>
					</div>
				</div>
			)}
			<div className="card mb-5 mb-xl-10 ">
				<form
					className="form fv-plugins-bootstrap5 fv-plugins-framework"
					noValidate
					id="kt_login_signup_form"
					onSubmit={formik.handleSubmit}
				>
					<div className="card-body border-top p-9">
						<div className="row mb-lg-6">
							<div className="col-lg-12">
								<label className="col-form-label fw-bold fs-6 required">
									Current Password
								</label>
								<div className="">
									<input
										placeholder="Current Password"
										type="password"
										autoComplete="off"
										className="form-control"
										name="current_password"
										value={formik.values.current_password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.current_password &&
										formik.errors.current_password && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{`${formik.errors.current_password}`}</span>
												</div>
											</div>
										)}
								</div>
							</div>
						</div>
						<div className="row mb-lg-6">
							<div className="col-lg-12">
								<label className="col-form-label fw-bold fs-6 required">
									New Password
								</label>
								<div className="">
									<input
										placeholder="New Password"
										type="password"
										autoComplete="off"
										className="form-control"
										name="new_password"
										value={formik.values.new_password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
									{formik.touched.new_password &&
										formik.errors.new_password && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{`${formik.errors.new_password}`}</span>
												</div>
											</div>
										)}
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<button
							type="submit"
							id="kt_sign_up_submit"
							className="btn btn-primary"
							disabled={loading}
						>
							{!loading && <span className="indicator-label">{SUBMIT}</span>}
							{loading && (
								<span
									className="indicator-progress"
									style={{ display: 'block' }}
								>
									{PLEASE_WAIT}
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};
const mapStateToProps = (state: any) => {
	return {
		loading: state.resetPasswordReducer.loading,
		error: state.resetPasswordReducer.error,
		res: state.resetPasswordReducer.res,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		resetPassword: (newPassword: any, callback: any) =>
			dispatch(resetPassword(newPassword, callback)),
	};
};
const connectComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPassword);
export default connectComponent;
