import { useEffect, useState } from 'react';

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			if (window.innerWidth < 575) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener('resize', handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return { windowSize, isMobile };
}
