/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
	removeObjectFromLocalStorage,
	searchparam,
	showToastMessageFailure,
	showToastMessageSuccess,
} from '../../helpers/helperFunction';
import { VIEW_CANDIDATE } from '../../helpers/routesConstant';
import { fetchInterviewsByCandidateId } from '../Interviews/interviewAction';
import {
	ADD_CANDIDATE_FAILURE,
	ADD_CANDIDATE_REQUEST,
	ADD_CANDIDATE_SUCCESS,
	GET_ACTIVE_CANDIDATE_FAILURE,
	GET_ACTIVE_CANDIDATE_REQUEST,
	GET_ACTIVE_CANDIDATE_SUCCESS,
	GET_ALL_CANDIDATE_FAILURE,
	GET_ALL_CANDIDATE_REQUEST,
	GET_ALL_CANDIDATE_SUCCESS,
	GET_CANDIDATE_BY_ID_FAILURE,
	GET_CANDIDATE_BY_ID_REQUEST,
	GET_CANDIDATE_BY_ID_SUCCESS,
	GET_CANDIDATE_SKILLS_FAILURE,
	GET_CANDIDATE_SKILLS_REQUEST,
	GET_CANDIDATE_SKILLS_SUCCESS,
	IMPORT_CANDIDATE_FAILURE,
	IMPORT_CANDIDATE_REQUEST,
	IMPORT_CANDIDATE_SUCCESS,
	UPDATE_CANDIDATE_FAILURE,
	UPDATE_CANDIDATE_REQUEST,
	UPDATE_CANDIDATE_SUCCESS,
	UPDATE_RECRUITMENT_STATUS_ID_FAILURE,
	UPDATE_RECRUITMENT_STATUS_ID_REQUEST,
	UPDATE_RECRUITMENT_STATUS_ID_SUCESS,
} from '../actionTypes';
import {
	addCandidateService,
	getActiveCandidates,
	getAllCandidates,
	getCandidateById,
	getcandidateSkills,
	importCandidateCsvService,
	updateCandidateService,
	updateRecruitmentStatusIdService,
} from './candidateService';

const request = (type: string) => {
	return {
		type,
	};
};
const success = (type: string, data: object) => {
	return {
		type,
		payload: data,
	};
};
const failure = (type: string, error: string) => {
	return {
		type,
		payload: error,
	};
};

export const fetchActiveCandidates = (
	searchObj: searchparam,
	queryParams?: any
) => {
	return async (dispatch: any) => {
		dispatch(request(GET_ACTIVE_CANDIDATE_REQUEST));

		await getActiveCandidates(searchObj, queryParams).then(
			(result: any) =>
				dispatch(success(GET_ACTIVE_CANDIDATE_SUCCESS, result.data)),
			(error: any) =>
				dispatch(failure(GET_ACTIVE_CANDIDATE_FAILURE, error.message))
		);
	};
};

export const fetchAllCandidates = (
	searchObj: searchparam,
	queryParams?: any
) => {
	return async (dispatch: any) => {
		dispatch(request(GET_ALL_CANDIDATE_REQUEST));

		await getAllCandidates(searchObj, queryParams).then(
			(result: any) =>
				dispatch(success(GET_ALL_CANDIDATE_SUCCESS, result.data)),
			(error: any) =>
				dispatch(failure(GET_ALL_CANDIDATE_FAILURE, error.message))
		);
	};
};

export const fetchCandidateById = (id: any) => {
	return async (dispatch: any) => {
		dispatch(request(GET_CANDIDATE_BY_ID_REQUEST));

		await getCandidateById(id).then(
			(result: any) =>
				dispatch(success(GET_CANDIDATE_BY_ID_SUCCESS, result.data)),
			(error: any) =>
				dispatch(failure(GET_CANDIDATE_BY_ID_FAILURE, error.message))
		);
	};
};

export const fetchCandidateSkills = (id: any) => {
	return async (dispatch: any) => {
		dispatch(request(GET_CANDIDATE_SKILLS_REQUEST));

		await getcandidateSkills(id).then(
			(result: any) =>
				dispatch(success(GET_CANDIDATE_SKILLS_SUCCESS, result.data)),
			(error: any) =>
				dispatch(failure(GET_CANDIDATE_SKILLS_FAILURE, error.message))
		);
	};
};

export const addCandidate = (formData: any, callback: any) => {
	return async (dispatch: any) => {
		dispatch(request(ADD_CANDIDATE_REQUEST));

		await addCandidateService(formData).then(
			(result: any) => {
				showToastMessageSuccess('Candidate added succesfully');
				dispatch(success(ADD_CANDIDATE_SUCCESS, result));
				callback(VIEW_CANDIDATE);
				removeObjectFromLocalStorage('addCandidate');
			},
			(error: any) => {
				dispatch(failure(ADD_CANDIDATE_FAILURE, error.message));
				showToastMessageFailure();
			}
		);
	};
};

export const importCandidateCsv = (formData: any, navigate: any) => {
	return async (dispatch: any) => {
		dispatch(request(IMPORT_CANDIDATE_REQUEST));

		await importCandidateCsvService(formData).then(
			(result: any) => {
				showToastMessageSuccess('Candidates imported succesfully');
				dispatch(fetchActiveCandidates);
				dispatch(success(IMPORT_CANDIDATE_SUCCESS, result));
				navigate(VIEW_CANDIDATE);
			},
			(error: any) => {
				dispatch(failure(IMPORT_CANDIDATE_FAILURE, error.message));
				showToastMessageFailure(error.response?.data?.message);
			}
		);
	};
};

export const updateCandidate = (
	fromData: object,
	id: number,
	callback: Function
) => {
	return async (dispatch: any) => {
		dispatch(request(UPDATE_CANDIDATE_REQUEST));

		await updateCandidateService(fromData, id).then(
			(result: any) => {
				dispatch(success(UPDATE_CANDIDATE_SUCCESS, result));
				showToastMessageSuccess('Candidate updated successfully');
				callback(VIEW_CANDIDATE);
			},
			(error: any) => {
				dispatch(failure(UPDATE_CANDIDATE_FAILURE, error.message));
				showToastMessageFailure();
			}
		);
	};
};
export const updateRecruitmentStatusId = (
	fromData: object,
	id: number,
	location: any = {}
) => {
	return async (dispatch: any) => {
		dispatch(request(UPDATE_RECRUITMENT_STATUS_ID_REQUEST));

		await updateRecruitmentStatusIdService(fromData, id).then(
			(result: any) => {
				dispatch(success(UPDATE_RECRUITMENT_STATUS_ID_SUCESS, result));
				if (location.href.includes('add-interview')) {
					return;
				} else {
					showToastMessageSuccess('Recruitment Status updated successfully');
				}
				dispatch(fetchInterviewsByCandidateId(id));
			},
			(error: any) => {
				dispatch(failure(UPDATE_RECRUITMENT_STATUS_ID_FAILURE, error.message));
			}
		);
	};
};
