/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import {
	convertDate,
	convertDateFromat,
} from '../../../helpers/helperFunction';

interface Props {
	candidateRes: any;
	previousComapny: any;
	skill: any;
}

const CandidateDetailsAccordian = ({
	candidateRes,
	previousComapny,
	skill,
}: Props) => {
	return (
		<div>
			<div className="card mb-5 mb-xl-10">
				<div className="px-9 row">
					<div className="m-0">
						<div className="d-flex justify-content-between ">
							<div
								className="d-flex align-items-center collapsible py-3 toggle mb-0"
								data-bs-toggle="collapse"
								data-bs-target="#kt_job_8_1"
							>
								<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
									<span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
												opacity="0.3"
												x="2"
												y="2"
												width="20"
												height="20"
												rx="5"
												fill="currentColor"
											/>
											<rect
												x="6.0104"
												y="10.9247"
												width="12"
												height="2"
												rx="1"
												fill="currentColor"
											/>
										</svg>
									</span>
									<span className="svg-icon toggle-off svg-icon-1">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
												opacity="0.3"
												x="2"
												y="2"
												width="20"
												height="20"
												rx="5"
												fill="currentColor"
											/>
											<rect
												x="10.8891"
												y="17.8033"
												width="12"
												height="2"
												rx="1"
												transform="rotate(-90 10.8891 17.8033)"
												fill="currentColor"
											/>
											<rect
												x="6.01041"
												y="10.9247"
												width="12"
												height="2"
												rx="1"
												fill="currentColor"
											/>
										</svg>
									</span>
								</div>
								<h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
									Important Details
								</h4>
							</div>
							{candidateRes &&
								candidateRes.resume_id &&
								candidateRes.resume_id !== 'undefined' && (
									<a
										href={candidateRes.resume_id}
										target="_blank"
										rel="noreferrer"
									>
										<button className="btn btn-light mt-2">Resume</button>
									</a>
								)}
						</div>

						<div id="kt_job_8_1" className="collapse show fs-6 ms-1">
							<div className="row ps-10">
								<div className="col-lg-4 mb-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Name
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.name ? candidateRes?.name : '-'}
										</span>
									</div>
								</div>
								<div className=" col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Email
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.email ? candidateRes?.email : '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Contact Number
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.contect_no
												? candidateRes?.contect_no
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Degree
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.degree?.degree
												? candidateRes?.degree?.degree
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Passing Grade
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.passing_grade
												? candidateRes?.passing_grade
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Skills
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{skill}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Current Salary
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.current_salary
												? candidateRes?.current_salary
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Expected Salary
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.expected_salary
												? candidateRes?.expected_salary
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Total Experience
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{/* {candidateRes?.total_experience}{' '}
											{candidateRes?.total_experience === Number(1)
												? 'year'
												: 'years'} */}
											{candidateRes.total_experience == 1
												? `${candidateRes.total_experience} Year`
												: candidateRes.total_experience
												? `${candidateRes.total_experience} Years`
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Mode of Work
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.mode_of_work?.mode_of_work
												? candidateRes?.mode_of_work?.mode_of_work
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Source of Connection
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.source?.source
												? candidateRes?.source?.source
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											recruitment status
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.recruitment__status?.recruitment_status
												? candidateRes?.recruitment__status?.recruitment_status
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Remarks
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.remarks === 'null' ||
											!candidateRes?.remarks
												? '-'
												: candidateRes?.remarks}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Resume Date
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.resume_date === 'null' ||
											!candidateRes?.resume_date
												? '-'
												: convertDateFromat(candidateRes?.resume_date)}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="separator separator-dashed"></div>
					</div>

					<div className="m-0">
						<div
							className="d-flex align-items-center collapsible py-3 toggle mb-0"
							data-bs-toggle="collapse"
							data-bs-target="#kt_job_8_2"
						>
							<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
								<span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											opacity="0.3"
											x="2"
											y="2"
											width="20"
											height="20"
											rx="5"
											fill="currentColor"
										/>
										<rect
											x="6.0104"
											y="10.9247"
											width="12"
											height="2"
											rx="1"
											fill="currentColor"
										/>
									</svg>
								</span>
								<span className="svg-icon toggle-off svg-icon-1">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											opacity="0.3"
											x="2"
											y="2"
											width="20"
											height="20"
											rx="5"
											fill="currentColor"
										/>
										<rect
											x="10.8891"
											y="17.8033"
											width="12"
											height="2"
											rx="1"
											transform="rotate(-90 10.8891 17.8033)"
											fill="currentColor"
										/>
										<rect
											x="6.01041"
											y="10.9247"
											width="12"
											height="2"
											rx="1"
											fill="currentColor"
										/>
									</svg>
								</span>
							</div>
							<h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
								Other Details
							</h4>
						</div>

						<div id="kt_job_8_2" className="collapse show fs-6 ms-1">
							<div className="row ps-10">
								<div className="col-lg-4 mb-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Address
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.address === 'null' ||
											!candidateRes?.address
												? '-'
												: candidateRes?.address}
										</span>
									</div>
								</div>
								<div className=" col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											city
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.city === 'null' || !candidateRes?.city
												? '-'
												: candidateRes?.city}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											state
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.state === 'null' || !candidateRes?.state
												? '-'
												: candidateRes?.state}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											passing year
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{candidateRes?.passing_year === 'null' ||
											!candidateRes?.passing_year
												? '-'
												: candidateRes?.passing_year}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											notice period
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{/* {candidateRes?.notice_period === 'null' ||
											!candidateRes?.notice_period
												? '-'
												: candidateRes?.notice_period}{' '} */}
											{candidateRes.notice_period == 1
												? `${candidateRes.notice_period} Month`
												: candidateRes.notice_period
												? `${candidateRes.notice_period} Months`
												: '-'}
										</span>
									</div>
								</div>
								<div className="col-lg-4 mb-4">
									<div className="d-flex flex-column">
										<span className="text-start fs-7 text-uppercase gs-0">
											Previous companies
										</span>
										<span className="mb-4 text-gray-600 fw-semibold fs-6">
											{previousComapny ? previousComapny : '-'}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="separator separator-dashed"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CandidateDetailsAccordian;
