import { getActiveCandidateReducer } from './viewCandidateReducer';
import { getAllCandidateReducer } from './viewAllCandidateReducer';
import { getCandidateByIDReducer } from './viewCandidateByIdReducer';
import { addCandidateReducer } from './addCandidateReducer';
import { updateCandidateReducer } from './updateCandidateReducer';
import { viewCandidateSkillsReducer } from './viewCandidateSkills';
import { importCSVReducer } from './importCSVReducer';

const candidateReducer = {
	getActiveCandidateReducer,
	getAllCandidateReducer,
	getCandidateByIDReducer,
	addCandidateReducer,
	updateCandidateReducer,
	viewCandidateSkillsReducer,
	importCSVReducer,
};

export default candidateReducer;
