/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { axiosInstance } from '../../helpers/apiRequest';
import {
	CANDIDATE,
	CANDIDATE_REPORT,
	CANDIDATE_SKILLS,
	UPDATE_RECRUITMENT_STATUS,
} from '../../helpers/config';
import {
	getSearchQueryString,
	searchparam,
} from '../../helpers/helperFunction';

export const getActiveCandidates = async (
	searchObj: searchparam,
	queryParams?: any
) => {
	const queryString = getSearchQueryString(searchObj);
	const res = await axiosInstance.get(`${CANDIDATE}?${queryString}`);
	return res;
};

export const getAllCandidates = async (
	searchObj: searchparam,
	queryParams?: any
) => {
	const queryString = getSearchQueryString(searchObj);
	const res = await axiosInstance.get(`${CANDIDATE_REPORT}?${queryString}`);
	return res;
};

export const getCandidateById = async (id: any) => {
	const res = await axiosInstance.get(`${CANDIDATE_REPORT}/${id}`);
	return res;
};

export const getcandidateSkills = async (id: any) => {
	const res = await axiosInstance.get(`${CANDIDATE_SKILLS}/${id}`);
	return res;
};

export const addCandidateService = async (formData: any) => {
	return axiosInstance({
		method: 'post',
		url: CANDIDATE_REPORT,
		data: formData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};
export const importCandidateCsvService = async (formData: any) => {
	return axiosInstance({
		method: 'post',
		url: `${CANDIDATE_REPORT}/import`,
		data: formData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

export const updateCandidateService = async (formData: any, id: number) => {
	return axiosInstance({
		method: 'post',
		url: `${CANDIDATE_REPORT}/update/${id}`,
		data: formData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

export const updateRecruitmentStatusIdService = async (
	formData: any,
	id: number
) => {
	return axiosInstance({
		method: 'put',
		url: `${UPDATE_RECRUITMENT_STATUS}/${id}/update-recruitment-status`,
		data: { recruitment_status_id: Number(formData) },
	});
};
