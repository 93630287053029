/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
import {
	FC,
	useState,
	useEffect,
	createContext,
	useContext,
	useRef,
	Dispatch,
	SetStateAction,
} from 'react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { WithChildren } from '../../../../_metronic/helpers';

type AuthContextProps = {
	auth: AuthModel | undefined;
	saveAuth: (auth: AuthModel | undefined) => void;
	currentUser: UserModel | undefined;
	setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
	logout: () => void;
};

const initAuthContextPropsState = {
	auth: authHelper.getAuth(),
	saveAuth: () => {},
	currentUser: undefined,
	setCurrentUser: () => {},
	logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
	const saveAuth = (auth: AuthModel | undefined) => {
		setAuth(auth);
		if (auth) {
			authHelper.setAuth(auth);
		} else {
			authHelper.removeAuth();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentUser(undefined);
	};

	return (
		<AuthContext.Provider
			value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

const AuthInit: FC<WithChildren> = ({ children }) => {
	const { auth, logout, setCurrentUser } = useAuth();
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);

	useEffect(() => {
		const requestUser = async (auth: any) => {
			try {
				if (!didRequest.current) {
					setCurrentUser(auth);
				}
			} catch (error) {
				console.error(error);
				if (!didRequest.current) {
					logout();
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (auth && auth.token) {
			requestUser(auth);
		} else {
			logout();
			setShowSplashScreen(false);
		}
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
