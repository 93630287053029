/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { useLocation } from 'react-router-dom';

interface Props {
	handleSorting: any;
}

const InterviewReportTableHeader = ({ handleSorting }: Props) => {
	const location = useLocation();
	const [sortOrder, setSortOrder] = useState('desc');

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const sortingOrder = searchParams.get('sort_order');
		sortingOrder && setSortOrder(sortingOrder);
	}, [location]);
	return (
		<thead>
			<tr className="report-table-header">
				<th
					className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 cursor-pointer"
					id="id"
					onClick={(e) => handleSorting(e)}
				>
					Id
					{sortOrder === 'asc' ? (
						<KTSVG
							path="/media/icons/duotune/arrows/arr066.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					) : (
						<KTSVG
							path="/media/icons/duotune/arrows/arr065.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					)}
				</th>
				<th
					className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 cursor-pointer"
					id="name"
					onClick={(e) => handleSorting(e)}
				>
					candidate name
					{sortOrder === 'asc' ? (
						<KTSVG
							path="/media/icons/duotune/arrows/arr066.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					) : (
						<KTSVG
							path="/media/icons/duotune/arrows/arr065.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					)}
				</th>
				<th className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
					interviewer
				</th>
				<th className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
					interview type
				</th>
				<th className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
					interview mode
				</th>
				<th
					className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 cursor-pointer"
					id="date"
					onClick={(e) => handleSorting(e)}
				>
					date & time
					{sortOrder === 'asc' ? (
						<KTSVG
							path="/media/icons/duotune/arrows/arr066.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					) : (
						<KTSVG
							path="/media/icons/duotune/arrows/arr065.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					)}
				</th>
				<th
					className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 cursor-pointer"
					id="total_rating"
					onClick={(e) => handleSorting(e)}
				>
					total ratings
					{sortOrder === 'asc' ? (
						<KTSVG
							path="/media/icons/duotune/arrows/arr066.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					) : (
						<KTSVG
							path="/media/icons/duotune/arrows/arr065.svg"
							className="svg-icon-3 me-1 m-0"
						/>
					)}
				</th>

				<th className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
					status
				</th>
			</tr>
		</thead>
	);
};

export default InterviewReportTableHeader;
