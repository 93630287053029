import { KTSVG } from '../../_metronic/helpers';

export const REQUIRED = 'Required';
export const NO_DOT_ALLOWED = 'No dots allowed';
// export const ADVOCATE = "Advocate";
export const SUBMIT = 'Submit';
export const NEXT = (
	<>
		<span>Next </span>
		<KTSVG
			path="/media/icons/duotune/arrows/arr064.svg"
			className="svg-icon-4 ms-1 m-0"
		/>
	</>
);
export const BACK = (
	<>
		<KTSVG
			path="/media/icons/duotune/arrows/arr063.svg"
			className="svg-icon-3 me-1 m-0"
		/>
		<span>Back </span>
	</>
);
export const DISCARD = 'Discard';
export const CONTINUE = 'Continue';
export const PLEASE_WAIT = (
	<>
		Please wait...
		<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
	</>
);

export const NO_RECORDS_FOUND = 'No Records Found';

export const DEGREE_CONST = 'degree';
export const SOURCE_CONST = 'source';
export const SKILL_TYPE_CONST = 'skill-types';
export const SKILL_CONST = 'skills';
export const RECRUITMENT_STATUS_CONST = 'recruitment-status';
export const MODE_OF_WORK_CONST = 'mode-of-work';
export const INTERVIEW_TYPE_CONST = 'interview-type';
export const INTERVIEWER_CONST = 'interviewer';
export const INTERVIEW_MODE_CONST = 'interview-mode';
