import { axiosInstance } from '../../helpers/apiRequest';
import { LOGIN, LOGOUT, RESET_PASSWORD } from '../../helpers/config';

export const loginService = (loginDetails: any) => {
	const res = axiosInstance.post(LOGIN, loginDetails);
	return res;
};

export const logoutService = () => {
	const res = axiosInstance.post(LOGOUT);
	return res;
};

export const resetPasswordService = async (newPass: Object) => {
	return axiosInstance.post(RESET_PASSWORD, newPass);
};
