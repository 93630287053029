/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
interface Props {
	selectedSearchvalues: any;
	handleRemoveFilter: any;
	values: any;
	setFieldValue: any;
}

const CandidateFilters = ({
	selectedSearchvalues,
	handleRemoveFilter,
	setFieldValue,
}: Props) => {
	return (
		<div>
			<div className="d-flex filter-section">
				{Object.keys(selectedSearchvalues).map((key, index) => {
					const value = selectedSearchvalues[key];
					return (
						value && (
							<div
								key={index}
								className="btn btn-sm bg-body btn-color-gray-700 fw-bold"
								onClick={() => handleRemoveFilter(key, setFieldValue)}
							>
								{value}
								&nbsp;
								<span>
									<i className="fa fa-times"></i>
								</span>
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export default CandidateFilters;
