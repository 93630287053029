/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
import { Field } from 'formik';
import React, { useState } from 'react';
import {
	NO_RECORDS_FOUND,
	PLEASE_WAIT,
	SUBMIT,
} from '../../../helpers/globalConstant';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

interface Props {
	interviewTypeRes: any;
	interviewerRes: any;
	interviewModeRes: any;
	addInterviewloading: any;
	updateInterviewloading: any;
	errors: any;
	touched: any;
	recruitmentStatusRes: any;
	setFieldValue: any;
}

const AddInterviewForm = ({
	interviewTypeRes,
	interviewerRes,
	interviewModeRes,
	addInterviewloading,
	updateInterviewloading,
	errors,
	touched,
	setFieldValue,
}: Props) => {
	const params = useParams();
	const [startDate, setStartDate] = useState(new Date());

	return (
		<>
			<div className="row">
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Interview Type
					</label>
					<Field
						as="select"
						name="interview_type_id"
						className={clsx('form-control bg-transparent form-select')}
					>
						<option value={''} disabled>
							Select Interview Type
						</option>
						{interviewTypeRes && interviewTypeRes.length > 0 ? (
							interviewTypeRes?.map((list: any, i: number) => (
								<>
									<option key={i} value={list.id}>
										{list.interview_type}
									</option>
								</>
							))
						) : (
							<option>{NO_RECORDS_FOUND}</option>
						)}
					</Field>
					{errors.interview_type_id && touched.interview_type_id && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.interview_type_id}`}</span>
							</div>
						</div>
					)}
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Interviewer
					</label>
					<Field
						as="select"
						name="interviewer_id"
						className={clsx('form-control bg-transparent form-select')}
					>
						<option value={''} disabled>
							Select Interviewer
						</option>
						{interviewerRes && interviewerRes.length > 0 ? (
							interviewerRes?.map((list: any, i: any) => (
								<>
									<option key={i} value={list.id}>
										{list.name}
									</option>
								</>
							))
						) : (
							<option>{NO_RECORDS_FOUND}</option>
						)}
					</Field>
					{errors.interviewer_id && touched.interviewer_id && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.interviewer_id}`}</span>
							</div>
						</div>
					)}
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Interview Mode
					</label>
					<Field
						as="select"
						name="interview_mode_id"
						className={clsx('form-control bg-transparent form-select')}
					>
						<option value={''} disabled>
							Select Interview Mode
						</option>
						{interviewModeRes && interviewModeRes.length > 0 ? (
							interviewModeRes?.map((list: any, i: any) => (
								<>
									<option key={i} value={list.id}>
										{list.interview_mode}
									</option>
								</>
							))
						) : (
							<option>{NO_RECORDS_FOUND}</option>
						)}
					</Field>
					{errors.interview_mode_id && touched.interview_mode_id && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.interview_mode_id}`}</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="row">
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">Date</label>

					<Datetime
						value={startDate}
						onChange={(date) => {
							const momentDate = moment(date);
							const selectedDate = momentDate.isValid()
								? momentDate.toDate()
								: null;
							selectedDate && setStartDate(selectedDate);
							setFieldValue('date', moment(date).format('YYYY-MM-DDTHH:mm'));
						}}
						dateFormat="DD/MM/YYYY"
						timeFormat="hh:mm A"
					/>
					{errors.date && touched.date && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.date}`}</span>
							</div>
						</div>
					)}
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6 required">
						Location/Link
					</label>
					<Field
						placeholder="Location/Link"
						type="text"
						name="location_link"
						autoComplete="off"
						className={clsx('form-control bg-transparent')}
					/>
					{errors.location_link && touched.location_link && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{`${errors.location_link}`}</span>
							</div>
						</div>
					)}
				</div>
				<div className="col-lg-4">
					<label className="col-form-label fw-bold fs-6">Remarks</label>
					<Field
						placeholder="Remarks"
						type="text"
						name="remarks"
						autoComplete="off"
						className={clsx('form-control bg-transparent')}
					/>
				</div>
			</div>

			<button
				type="submit"
				className="btn btn-primary mt-3"
				disabled={addInterviewloading}
			>
				{params.interviewId
					? !updateInterviewloading && SUBMIT
					: !addInterviewloading && SUBMIT}
				{/* {!addInterviewloading && SUBMIT} */}
				{(addInterviewloading || updateInterviewloading) && (
					<span className="indicator-progress" style={{ display: 'block' }}>
						{PLEASE_WAIT}
					</span>
				)}
			</button>
		</>
	);
};

export default AddInterviewForm;
